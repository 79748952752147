const LANGUAGE = {
    ['ptbr']: {
        menu: ['Sobre', 'Soluções','Contato'],
        header: {
            titulo: 'Seu negócio no próximo nível',
            subtitulo: 'Construa soluções inovadoras unindo planejamento e tecnologia de quem entende do assunto ;)',
            botao: 'CONHEÇA NOSSAS SOLUÇÕES'
        },
        secao_sobre: {
            titulo1: 'Nossão Missão:',
            titulo2: 'tecnologia +',
            titulo3: 'planejamento',
            subtitulo: 'para ajudar empresas a <b className="paragrafo-heavy">irem mais longe</b>',
            texto1: '<label className="paragrafo-heavy">A Sigrun nasceu da decisão de usar nossa paixão por tecnologia como forma de fomentar o mercado,</label> ajudando empresas em seus desafios tecnológicos e mantendo o cliente sempre no centro de nossas estratégias.',
            texto2: 'Antenados nas principais mudanças e novidades do mundo digital, somos um super time, focados em transformar uma ideia em realidade através de um aplicativo, game, site ou qualquer outra solução que fortaleça seu posicionamento de marca e relacionamento com os clientes.'
        },
        secao_solucoes:{
            texto: 'Soluções para levar sua empresa <label className="paragrafo-heavy">a outro patamar</label>',
            cards: [{
                titulo: 'aplicativos_',
                texto: 'Melhore o relacionamento com clientes e a valorização de sua marca com um app personalizado'
            },
            {
                titulo: 'sistemas web_',
                texto: 'De site a game, sistemas web completos para levar sua marca ao próximo nível'
            },
            {
                titulo: 'e-commerce_',
                texto: 'Multiplique suas vendas com uma plataforma incrível para vender online'
            }]
        },
        secao_contato: {
            titulo: 'Dê o primeio passo_',
            labels: ['Nome', 'E-mail', 'Telefone'],
            botao: 'ENTRE EM CONTATO'
        },
        footer:{
            botoes: ['Sobre', 'Soluções','Contato'],
            texto_social_media: '<label className="paragrafo-heavy">ACOMPANHE</label> NOSSAS REDES SOCIAIS'
        }
    },
    ['eng']: {
        menu: ['About us', 'Solutions', 'Contact'],
        header:{
            titulo: 'Your business at the next level',
            subtitulo: 'Build innovative solutions by uniting planning and technology from those who understand the subject ;)',
            botao: 'LEARN ABOUT OUR SOLUTIONS'
        },
        secao_sobre: {
            titulo1: 'Our Mission:',
            titulo2: 'technology +',
            titulo3: 'planning',
            subtitulo: 'to help companies <b className="paragrafo-heavy">go further</b>',
            texto1: '<label className="paragrafo-heavy">Sigrun was born from the decision to use our passion for technology as a way to promote the market,</label> helping companies with their technological challenges and keeping the customer always at the center of our strategies.',
            texto2: 'Tuned into the main changes and news in the digital world, we are a super team, focused on turning an idea into reality through an application, game, website or any other solution that strengthens your brand positioning and relationship with customers.'
        },
        secao_solucoes:{
            texto: 'Solutions to <label className="paragrafo-heavy">evolve</label> your business',
            cards: [{
                titulo: 'apps_',
                texto: 'Improve customer relationships and enhance your brand with a custom app'
            },
            {
                titulo: 'web systems_',
                texto: 'From website to game, complex web systems to take your brand to the next level'
            },
            {
                titulo: 'e-commerce_',
                texto: 'Multiply your sales with an incredible platform to sell online'
            }]
        },
        secao_contato: {
            titulo: 'Take the first step_',
            labels: ['Name', 'Email', 'Phone'],
            botao: 'CONTACT US'
        },
        footer:{
            botoes: ['About us', 'Solutions', 'Contact'],
            texto_social_media: '<label className="paragrafo-heavy">FOLLOW</label> OUR SOCIAL MEDIA'
        }
    }
}

export function getPageText(language){
    return LANGUAGE[language];
}