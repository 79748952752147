import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import { getPageText } from './util/language';
import $ from 'jquery';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [isButtonContactDisabled, setIsButtonContactDisabled] = useState(false);
  const [contactPayload, setContactPayload] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [pageText, setPageText] = useState(null);
  const defaultOptions = {
    position: 'top-right',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored' ?? 'dark'
};

  const handleChangeLanguage = (lang) => {
    $('.lang').removeClass('active-language');
    $(`#lang-${lang}`).addClass('active-language');
    setPageText(getPageText(lang));
  }

  useEffect(() => {
    setPageText(getPageText('ptbr'));
  }, []);

  $(document).on('click', '.nav-bar-mobile li', function(){
    $('.nav-bar-mobile').hide();
  });

  $(document).on('click', '#show-menu-mobile', function(){
    $('.nav-bar-mobile').show();
  });

  const handleContactFormSubmit = () => {
    setIsButtonContactDisabled(true);

    const nameInput = document.querySelector("#contato input[type='text'][placeholder='Nome']");
    const emailInput = document.querySelector("#contato input[type='text'][placeholder='E-mail']");
    const phoneInput = document.querySelector("#contato input[type='text'][placeholder='Telefone']");

    const contact = {
      name: nameInput.value,
      email: emailInput.value,
      phone: phoneInput.value,
    }

    if ([contact.name, contact.email, contact.phone].includes('')) {
      toast.warning('Preencha o nome, e-mail e telefone para entrar em contato.', defaultOptions);

      setTimeout(() => {
        setIsButtonContactDisabled(false);  
      }, 300);

      return false;
    }

    axios
      .post(`${'http://sigrunsolutions.com:3001' ?? process?.env?.API_URL}/contacts`, contact)
      .then((response) => {
        toast.success('Contato enviado com sucesso!', defaultOptions);

        setIsButtonContactDisabled(false);
      })
      .catch((error) => {
        if(error?.response?.status >= 400){
          toast.warning('Ocorreu algum problema ao enviar o contato! Por favor, tente novamente mais tarde.', defaultOptions);
        } else {
          toast.error('Ocorreu algum erro! Por favor, contate o suporte.', defaultOptions);
        }

        setIsButtonContactDisabled(false);
      });
  }

  return (
    <div className="App">
    <ToastContainer newestOnTop={true} />
    <header className="">      
      <div class="shadow">
        <div className="header-institucional">
          <ul className="nav-bar-mobile nav-bar-language">
            <li><img className="menu-icon" src="template/assets/images/icons/_menu.png" alt="" /></li>
            <li>
              <a onClick={() => handleChangeLanguage('ptbr')} id="lang-ptbr" href="#" className="lang active-language">PT/BR</a>
              <label> | </label>
              <a onClick={() => handleChangeLanguage('eng')} id="lang-eng" href="#" className='lang'>ENG</a>
            </li>
            <li><a href="#sobre">{pageText?.menu[0] || ""}</a></li>
            <li><a href="#solucoes">{pageText?.menu[1] || ""}</a></li>
            <li><a href="#contato">{pageText?.menu[2] || ""}</a></li>
          </ul>
          <div className="header-default">
            <img className="logo" src="template/assets/images/logo.png" alt="" />
            <ul className="nav-bar nav-bar-language">
              <li><a href="#sobre">{pageText?.menu[0] || ""}</a></li>
              <li><a href="#solucoes">{pageText?.menu[1] || ""}</a></li>
              <li><a href="#contato">{pageText?.menu[2] || ""}</a></li>
              <li>
                <a onClick={() => handleChangeLanguage('ptbr')} id="lang-ptbr" href="#" className="lang active-language">PT/BR</a>
                <label>|</label>
                <a onClick={() => handleChangeLanguage('eng')} id="lang-eng" href="#" className='lang'>ENG</a>
              </li>
            </ul>
            <img id="show-menu-mobile" src="template/assets/images/icons/_menu.png" alt=""/>
          </div>
          <h1 className="title-default title-institucional">{pageText?.header?.titulo}</h1>
          <div className="sub-header">
            <p className="header-paragraph">{pageText?.header?.subtitulo}</p>
            <a href="#solucoes" className="header-button button-default">{pageText?.header?.botao}</a>
          </div>
        </div>
      </div>
    </header>
    <main>
      <section id="sobre">
        <div className="row alinhamento-vertical">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <h2 className="sobre-titulo">{pageText?.secao_sobre?.titulo1}</h2>
              <h3 className="sobre-subtitulo">{pageText?.secao_sobre?.titulo2}</h3>
              <h3 className="sobre-subtitulo">{pageText?.secao_sobre?.titulo3}</h3>
              <p className="sobre-paragrafo">{parse(pageText?.secao_sobre?.subtitulo || "")}</p> 
          </div> 
          <div className="col-xl-2 col-lg-2 sobre-arrow"> 
            <img src="template/assets/images/icons/_arrow.png" alt=""/>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12"> 
            <p className="sobre-descricao">{parse(pageText?.secao_sobre?.texto1 || "")}</p>
            <p className="sobre-descricao">{parse(pageText?.secao_sobre?.texto2 || "")}</p>
          </div>
        </div>  
      </section>
      <section id="solucoes">
        <div className="solucoes-texto alinhamento-vertical">
          <img class="solucoes-arrow" src="template/assets/images/icons/_arrow.png" alt=""/>
          <p>{parse(pageText?.secao_solucoes.texto || "")}</p>
        </div>
        <div className="flex-default figure">
          <figure className="figure-default">
            <img src="assets/images/figure-aplicativo.png" alt=""/>
            <h4 className="figure-title">{parse(pageText?.secao_solucoes.cards[0].titulo || "")}</h4>
            <p className="figure-text">{parse(pageText?.secao_solucoes.cards[0].texto || "")}</p>
          </figure>
          <figure className="figure-default">
            <img src="assets/images/figure-sistemas-web.png" alt=""/>
            <h4 className="figure-title">{parse(pageText?.secao_solucoes.cards[1].titulo || "")}</h4>
            <p className="figure-text">{parse(pageText?.secao_solucoes.cards[1].texto || "")}</p>
          </figure>
          <figure className="figure-default">
            <img src="assets/images/figure-ecommerce.png" alt=""/>
            <h4 className="figure-title">{parse(pageText?.secao_solucoes.cards[2].titulo || "")}</h4>
            <p className="figure-text">{parse(pageText?.secao_solucoes.cards[2].texto || "")}</p>
          </figure>
        </div>
      </section>
      <section id="contato">
          <div className="shadow">
            <div className="row contato-titulo">
              <h2>{parse(pageText?.secao_contato.titulo || "")}</h2>
            </div>
            <div className="row contato-form">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 form-group">
                <input 
                  type="text" 
                  className="input-underlined" 
                  placeholder={parse(pageText?.secao_contato.labels[0] || "")} 
                  defaultValue={contactPayload.name}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 form-group">
                <input 
                  type="text"
                  className="input-underlined"
                  placeholder={parse(pageText?.secao_contato.labels[1] || "")}
                  defaultValue={contactPayload.email}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 form-group">
                <input type="text"
                  className="input-underlined"
                  placeholder={parse(pageText?.secao_contato.labels[2] || "")}
                  defaultValue={contactPayload.phone}
                />
              </div>
            </div>
            <div className="row contato-button">
              <button className="button-default" onClick={() => handleContactFormSubmit()} disabled={isButtonContactDisabled ? true : false}>
                <span style={{display: isButtonContactDisabled ? 'block' : 'none'}}>
                  ENVIANDO...
                </span>
                <span style={{display: !isButtonContactDisabled ? 'block' : 'none'}}>
                  {parse(pageText?.secao_contato.botao || "")}
                </span>
              </button>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="row footer">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 footer-menu">
            <ul>
              <a href="#sobre"><li>{parse(pageText?.footer.botoes[0] || "")}</li></a>
              <a href="#solucoes"><li>{parse(pageText?.footer.botoes[1] || "")}</li></a>
              <a href="#contato"><li>{parse(pageText?.footer.botoes[2] || "")}</li></a>
            </ul>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 footer-link">
            <div>
              <p>{parse(pageText?.footer.texto_social_media || "")}</p>
              <a target="_blank" href="https://www.instagram.com/sigrunsolutions/" rel="noreferrer"><img src="template/assets/images/icons/Instagram.png" alt=""/></a>
              <a target="_blank" href="https://www.facebook.com/sigrunsolutions/" rel="noreferrer"><img src="template/assets/images/icons/Facebook.png" alt=""/></a>
            </div>
          </div>
        </div>
        <div className="row footer-logo alinhamento-vertical">
          <img src="template/assets/images/logo_gray.png" alt=""/>
        </div>
      </footer>
    </div>
  );
}

export default App;